var project_content_width = 0;
var outerContentWidth = 0;
$(document).ready(function() {
    $('#projects').fullpage({
/*        //Navigation
        */menu: '.menu',/*
        lockAnchors: true,
        anchors:[],*/
        navigation: true,/*
        navigationPosition: 'right',
        navigationTooltips: ['firstSlide', 'secondSlide'],
        showActiveTooltip: false,
        slidesNavigation: true,
        slidesNavPosition: 'right',

        //Scrolling
        css3: true,
        scrollingSpeed: 700,
        autoScrolling: false,
        fitToSection: true,
        fitToSectionDelay: 1000,
        scrollBar: false,
        easing: 'easeInOutCubic',
        easingcss3: 'ease',
        loopBottom: false,
        loopTop: false,*/
        loopHorizontal: false,/*
        continuousVertical: false,
        normalScrollElements: '#element1, .element2',
        scrollOverflow: false,
        touchSensitivity: 15,
        normalScrollElementTouchThreshold: 5,

        //Accessibility
        keyboardScrolling: true,
        animateAnchor: true,
        recordHistory: true,

        //Design
        controlArrows: true,*/
        verticalCentered: false,/*
        resize : false,
        sectionsColor : ['#ccc', '#fff'],
        paddingTop: '3em',
        paddingBottom: '10px',*/
        fixedElements: '.logo, .languages, .menu',/*
        responsiveWidth: 0,
        responsiveHeight: 0,

        //Custom selectors
        sectionSelector: '.section',
        slideSelector: '.slide',

        //events */
        onLeave: function(index, nextIndex, direction){
            if(nextIndex == 1){
                return false;
            } else if (nextIndex == 2){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#ffffff",
                }, 1500 );
            } else if (nextIndex == 3){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#dbd7cf",
                }, 1500 );
            } else if (nextIndex == 4){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#ffffff",
                }, 1500 );
            } else if (nextIndex == 5){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#dcd8d6",
                }, 1500 );
            } else if (nextIndex == 6){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#f2e1c1",
                }, 1500 );
            } else if (nextIndex == 7){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#ffffff",
                }, 1500 );
            /*} else if (nextIndex == 7){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#a5a5a5",
                }, 1500 );*/
            } else if (nextIndex == 8){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#ffffff",
                }, 1500 );
            } else if (nextIndex == 9){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#dbd7cf",
                }, 1500 );
            } else if (nextIndex == 10){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#c0c0be",
                }, 1500 );
            } else if (nextIndex == 11){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#f0ddce",
                }, 1500 );
            } else if (nextIndex == 12){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#bbc8d9",
                }, 1500 );
            } else if (nextIndex == 13){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#d2cdc9",
                }, 1500 );
            } else if (nextIndex == 14){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#ffffff",
                }, 1500 );
            /*} else if (nextIndex == 16){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#f6efe7",
                }, 1500 );
            } else if (nextIndex == 17){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#ffffff",
                }, 1500 );*/
            } else if (nextIndex == 15){
                $( "div.section, div.project_details_wrap" ).animate({
                  backgroundColor: "#dbd7cf",
                }, 1500 );
            }
            closeProjectDetails();
        },
        afterLoad: function(anchorLink, index){
            if (index != 1) {
                $('.menu').show();
                $('.menu_for_projects').hide();
                $('#fp-nav').show();
            }
        },/*
        afterRender: function(){},
        afterResize: function(){},
        afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex){},*/
        onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex){
            closeProjectDetails();
        }
    });
    $('.section').height($(window).height());

    $( "div.intro" ).animate({
          backgroundColor: "rgb(230,230,245)",
        }, 5000 ).delay(1000);

    $('.scroll-down_arrow').click(function(event) {
        $.fn.fullpage.moveTo('amateur-thes', 0);
    });

    var project_content_height = $(".project_content").height();
    var project_img_height = $(".project_content").height();
    var project_content_width = project_content_height * 3400 / 2550;
    var project_details_wrap_margin = 0;
    if (($(".project_content").width() - 208)/$(".project_content").height() < 3400/2550) {
        $('.project_title').css('max-height', 'none');
        project_content_width = $(".project_content").width() - 208;
        var project_title_height = (project_content_height - (project_content_width * 2550 / 3400))/2 + 113;
        project_img_height = project_content_width * 2550 / 3400;
        project_details_wrap_margin = (($(".project_content").height() - project_img_height) / 2);
        $('.project_title').height(project_title_height);
    } else {
        $('.project_title').css('max-height', 113);
    }
    var outerContentWidth = ($(document).width() - project_content_width) / 2;
    $('.fp-prev').css('left', outerContentWidth - 54);
    $('.fp-next').css('left', project_content_width + outerContentWidth + 14);
    $('#fp-nav li:first-child').hide();
    $('#fp-nav').css('margin-top', '+=8px');
    $('#fp-nav').hide();
    $('div.project_details_wrap').width(project_content_width + 3);
    $('div.project_details_wrap').css('left', outerContentWidth + 1);
    $('div.project_details_wrap').css('margin-top', project_details_wrap_margin - 3);
    $('div.project_details_wrap').height(project_img_height + 4);
    $('div.project_details_wrap').css('line-height', project_img_height + 'px');

    $('.plus').click(function(event) {
        if (  $( this ).css( "transform" ) == 'none' ){
            $(this).css("transform","rotate(45deg)");
        } else {
            $(this).css("transform","" );
        }

        if($(this).hasClass('open_details')) {
            openProjectDetails();
            $(this).removeClass('open_details');
        }
        else {
            closeProjectDetails();
            $(this).addClass('open_details');
        }
    });

    var id;
    $(window).resize(function() {
        doneResizing();
        clearTimeout(id);
        id = setTimeout(doneResizing, 700);
    });

    $("div.project_content").lazyload({
        effect : "fadeIn"
    });
});

function doneResizing(){
    project_content_width = $(".project_content").height() * 3400 / 2550;
    project_content_height = $(".project_content").height();
    project_img_height = $(".project_content").height();
    project_details_wrap_margin = 0;
    if (($(".project_content").width()  - 208)/$(".project_content").height() < 3400/2550) {
        $('.project_title').css('max-height', 'none');
        project_content_width = $(".project_content").width() - 208;
        project_img_height = project_content_width * 2550 / 3400;
        project_details_wrap_margin = (($(".project_content").height() - project_img_height) / 2);
        var project_title_height = (project_content_height - (project_content_width * 2550 / 3400))/2 + 113;
        $('.project_title').height(project_title_height);
    } else {
        $('.project_title').css('max-height', 113);
    }
    outerContentWidth = ($(document).width() - project_content_width) / 2;
    $('div.project_details_wrap').width(project_content_width + 3);
    $('div.project_details_wrap').css('left', outerContentWidth + 1);
    $('div.project_details_wrap').css('margin-top', project_details_wrap_margin - 1);
    $('div.project_details_wrap').height(project_img_height + 3);
    $('div.project_details_wrap').css('line-height', project_img_height + 'px');
    $('.fp-prev').css('left', outerContentWidth - 54);
    $('.fp-next').css('left', project_content_width + outerContentWidth + 14);
}

function closeProjectDetails() {
    $('.plus').addClass('open_details');
    $(".plus").css("transform","" );
    $('div.project_details_wrap').fadeOut(400);
        $( "div.project_title" ).animate({
      color: "rgb(0, 0, 0)",
    }, 400 );
    $('.fp-next').fadeIn(400);
    $('.fp-prev').fadeIn(400);
    $('.title_underline').fadeTo('400', 0);
}

function openProjectDetails() {
    $('div.project_details_wrap').fadeIn(400);
    $( "div.project_title" ).animate({
      color: "rgb(0, 0, 255)",
    }, 400 );
    //$('div.project_title').css('color', 'rgb(0, 0, 255)');
    $('.fp-next').fadeOut(400);
    $('.fp-prev').fadeOut(400);
    $('.title_underline').fadeTo('400', 1);
}

function setLanguage(lang) {
    var href = window.location.href;
    $.ajax({
      type: "GET",
      url: '/lang/'+lang,
      complete: function(){ //A function to be called when the request finishes (after success and error callbacks are executed) - from jquery docs
           //do smth if you need
           window.location.href = href;
           location.reload(); 
        }
    });
}